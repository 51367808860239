import axios from "axios";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore/lite";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../db/banco";
import ILinkGeradoISM from "../../types/ILinkGeradoISM";

export default function Principal() {
    // Pegar parametros da página
    const { idCampanhaParametro, idInfluenciadorParametro } = useParams<{ idCampanhaParametro: string, idInfluenciadorParametro: string }>();
    const [idBD, setIdBD] = useState("");

    // Gerar link    
    const [linkCruActionpay, setLinkCruActionpay] = useState<string>("");
    // 3 - Função para buscar o link gerado
    const fetchDataBuscarLinks = async () => {
        const formatoAPI = "json";

        try {
            // Chamada para a função Firebase Functions com os parâmetros
            const response = await axios.get(`https://us-central1-ism-teste.cloudfunctions.net/buscarLinks?offer=${idCampanhaParametro}&format=${formatoAPI}`);

            // Verifica se houve erro na resposta
            if (!response.data.error) {
                console.log("Resposta da API:", response.data.result);
                setLinkCruActionpay(response.data.result.links[0].url);
            } else {
                alert("Código errado, ou campanha não adicionada nos favoritos");
                console.log("Erro na resposta da API:", response.data.error);
            }
        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    };

    // 2 - Função que chama a fetchDataBuscarLinks e lida com erros
    const buscarLink = async () => {
        try {
            await fetchDataBuscarLinks();
        } catch (error) {
            console.error('Erro ao buscar dados da Actionpay:', error);
        }
    };
    // banco de dados
    const useCollectionRefLinkISM = collection(db, "linkGeradoISM");
    // 1 - Chama buscarLink quando idCampanhaParametro muda
    useEffect(() => {
        if (idCampanhaParametro) {
            // console.log("Buscando link para idCampanhaParametro:", idCampanhaParametro);                   
            // buscarLink();
            const obterLinkGeradoISM = async () => {   
                const  idInfluenciadorParametroConvertido = Number(idInfluenciadorParametro)            
                const dataBD = await getDocs(useCollectionRefLinkISM);
                const todosLinksGeradosISM: ILinkGeradoISM[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as ILinkGeradoISM);

                const somenteDesseLink: ILinkGeradoISM[] = todosLinksGeradosISM.filter(somenteEsseLink => somenteEsseLink.codigoDaCampanha === idCampanhaParametro &&
                    somenteEsseLink.codigoDoInfluenciador === idInfluenciadorParametroConvertido
                );

                console.log(`id campanha: ${idCampanhaParametro} - id influenciador: ${idInfluenciadorParametroConvertido}`);

                console.log("link de", somenteDesseLink);          
                

                somenteDesseLink.map(pegarId => {
                    setIdBD(pegarId.id);                    
                    if (!pegarId.linkActionpay) { // se não existir o link, 1- cria link / 2 - salva link - 3 - redireciona link                        
                        buscarLink();
                    } else { // caso já tenha salvo no banco de dados, redireciona direto.                        
                        window.location.href = pegarId.linkActionpay
                    }                    
                });
            }
            obterLinkGeradoISM();

        }
    }, [idCampanhaParametro]);

    // 4 e último - Redirecionar quando todos os parâmetros e o link estão disponíveis
    useEffect(() => {
        if (idCampanhaParametro && idInfluenciadorParametro && linkCruActionpay) {            
            const linkCriado = linkCruActionpay.split('subaccount')[0].concat(idInfluenciadorParametro);
            salvarLinkISM();
            console.log("Redirecionando para:", linkCriado);
            window.location.href = linkCriado;
        }
    }, [idCampanhaParametro, idInfluenciadorParametro, linkCruActionpay]);

    useEffect(() => {

    }, []);

    function salvarLinkISM() {
        const converte = String(idInfluenciadorParametro);
        const linkGeradoISM = {
            linkActionpay: linkCruActionpay.split('subaccount')[0].concat(converte)
        }
        editarLinkGeradoISM(linkGeradoISM);
    }

    async function editarLinkGeradoISM(novosDados: any) {
        if (idBD) {
            try {
                const linkGeradoISMRef = doc(db, "linkGeradoISM", idBD);

                await updateDoc(linkGeradoISMRef, novosDados);

                // alert("Dados editados");
                console.log("dados editados");

            } catch (error) {
                // alert("Nao deu certo")
                console.log("Não deu certo");

            }
        } else {
            console.log("Id não carregado");
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <p>Você está sendo redirecionado...</p>
            </header>
        </div>
    );
}
