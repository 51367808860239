import AppRoute from "./AppRoute";

function App() {
 
  return (
    <AppRoute />
  );
}

export default App;
