import { BrowserRouter, Route, Routes } from "react-router-dom";
import Principal from "./pages/Principal";
import SemParametro from "./pages/SemParametro";
import FaltandoUmParametro from "./pages/Principal/FaltandoUmParametro";

export default function AppRoute() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SemParametro />} />
                <Route path="/:idCampanhaParametro" element={<FaltandoUmParametro />} />
                <Route path="/:idCampanhaParametro/:idInfluenciadorParametro" element={<Principal />} />                
            </Routes>
        </BrowserRouter>
    )
}